/*
Color variables used throughout Z4B
*/

$default-background: #f8f9fb;

$text-grey: #6a7381;

$cerise-color: #DB305E;
$cerise-color-hover: #b31760;
$cerise-color-disabled: #f08ebc;

$blue-color: #4466ee;
$dark-blue-color: #0f2ea8;
$green-color: #00a880;
$black-color: #0a1018;
$black-color-hover: #6a7381;
$black-color-disabled: #b7bcc8;

$color-grey-button: #e6e9ef;
$color-grey-button-disabled: #d2d7e1;
$color-grey-button-hover: #f0f2f5;
