/* You can add global styles to this file, and also import other style files */
@import url('https://use.typekit.net/hej4ies.css');
@import '_colors';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'objektiv-mk3', sans-serif;
  background-color: $default-background;
  box-sizing: unset;
  min-height: 100vh;
}

.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media screen and (max-width: 771px) {
  .hide-mobile {
    display: none;
  }
}

.hide {
  display: none;
}

/* *, ::after, ::before {
    box-sizing: border-box
} */

/* Text styling classes */
.medium2 {
  font-weight: 500;
}

.medium {
  font-weight: 400;
}

.regular {
  font-weight: 300;
}

.cerise-text {
  color: $cerise-color !important;
}

.cerise-bg {
  background-color: $cerise-color !important;
}

button.cerise-bg {
  &:hover {
    background-color: $cerise-color-hover !important;
  }

  &:disabled {
    background-color: $cerise-color-disabled !important;
  }
}

.cerise-border {
  border-color: $cerise-color !important;

  &:hover {
    border-color: $cerise-color-hover !important;
  }
}

.black-bg {
  background-color: $black-color;

  &:hover {
    background-color: $black-color-hover;
  }

  &:disabled {
    background-color: $black-color-disabled;
  }
}

.black-text {
  color: $black-color;
}

.link-text,
.link-text:hover {
  color: $blue-color;
}

h1 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0.01em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

button {
  outline: none !important;
  font-family: inherit;
  color: $black-color;
}

input {
  font-family: inherit;
}

.link {
  display: inline-block;
  color: $blue-color;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  color: $dark-blue-color;
  text-decoration: underline;
}

.page-heading {
  margin-top: 36px;
  margin-bottom: 24px;
}

.page-heading-with-subheader {
  margin-top: 32px;
  margin-bottom: 24px;
}

.disable-overflow-body {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .page-heading {
    margin-top: 24px;
  }

  .page-heading-with-subheader {
    margin-top: 24px;
    margin-bottom: 20px;
  }
}

/* Global CSS variables (colors, ...) */
:root {
  /* Text */
  --zfb-text-black: #0a1018;
  --zfb-text-grey: #6a7381;
}

.text-color-primary-green {
  color: #00a880;
}

.small-modal-wrapper {
  padding: 24px;
}

.small-modal-wrapper .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  > * {
    margin-left: 8px;
  }
}

.list-container {
  margin-bottom: 16px;
}

.list-row {
  padding-top: 16px;
  padding-bottom: 16px;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: $black-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d2d7e1;
}

.list-row > div:first-child {
  white-space: nowrap;
  padding-right: 16px;
}

.list-row > div:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-and-text {
  display: flex;
  align-items: center;
}

.icons {
  margin-right: 8px;
}

.list-currency {
  font-weight: normal;
}
